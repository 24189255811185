
export const log = (message, ...args) => {
    console.log(message, ...args);
};
export const warn = (message, ...args) => {
    console.log("WARN:", message, ...args);
};
export const error = (message, ...args) => {
    console.error(message, ...args);
};
export const debug = (message, ...args) => {
    console.debug(message, ...args);
};
export const network = (message, ...args) => {
    console.warn(message, ...args);
};


