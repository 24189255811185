import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./Header";
import PageContainer from "./PageContainer";
import { PageTypes } from "./PageTypes";
import * as authutil from "./auth/AuthUtil";
import * as Logger from "./Utility/Logger";
import {
	GetConsumerInfoByUserId,
	GetMerchantInfoByEmail,
	GetMerchantInfoByEmailList,
	GetUserProfileByUserId,
	updateLastLoginDate,
} from "./auth/firebase";
import { DocumentData, getDocs } from "@firebase/firestore";
import { Merchant } from "./models/Merchant.type";
import { Consumer } from "./models/Consumer.type";
import { UserProfile } from "./models/UserProfile.type";
import { UserCredential } from "@firebase/auth-types";

type PageSwap = { pageName: string; pageSwapProps: object | undefined };
function App() {
	const [user, setUser] = useState<UserCredential>(); //this comes from Firebase Auth
	const [merchantInfo, setMerchantInfo] = useState<Merchant[]>(); //merchant table
	// const [consumerInfo, setConsumerInfo] = useState<Consumer>(); //consumerProfile table
	const [userProfile, setUserProfile] = useState<UserProfile>(); //userProfile table
	const [page, setPage] = useState<PageSwap>({
		pageName: PageTypes.LANDING,
		pageSwapProps: {},
	});

	let pageSwapProps: object | undefined;

	useEffect(() => {
		//login from Local Storage
		let loginInfo = authutil.startupLogin();
		if (!loginInfo) {
			logout();
		} else {
			setAppUser(loginInfo);
		}

		//navigate the page if ?page=
		let params = new URLSearchParams(window.location.search),
			page = params.get("page");
		if (page) {
			params.delete("page");
			Logger.log("params after delete", params);
			if (Object.values(PageTypes).includes(page)) pageSwap(page);
		}
	}, [1]);

	const setMerchInfo = (info: any) => {
		Logger.warn("LOG merchantinfo being set", info);
		setMerchantInfo(info);
	};
	const callToUpdateMerchantInfo = (callerName: string) => {
		let query = GetMerchantInfoByEmail(userProfile?.email);
		// if (userProfile?.emails?.length && userProfile?.emails.length > 0) {
		// 	query = GetMerchantInfoByEmailList([
		// 		userProfile?.defaultEmail,
		// 		...userProfile.emails,
		// 	]);
		// }
		Logger.network(callerName, "query callToUpdateMerchantInfo", query);
		try {
			query &&
				getDocs(query).then((resp) => {
					Logger.network(callerName, "response callToUpdateMerchantInfo", resp);

					let arr: DocumentData[] = [];
					resp.forEach((doc) => {
						Logger.log("Log callToUpdateMerchantInfo", doc.data());
						arr.push(doc.data());
					});
					if (arr.length > 0) {
						setMerchInfo(arr);
					} else {
						Logger.error(
							callerName,
							"GetMerchantInfoByEmail",
							"returned empty array",
						);
					}
				});
		} catch (err) {
			Logger.error(callerName, "GetMerchantInfoByEmail", err);
		}
	};
	// const callToUpdateConsumerInfo = (callerName: string) => {
	// 	let query = GetConsumerInfoByUserId(user?.user?.uid);
	// 	// if (userProfile?.emails?.length && userProfile?.emails.length > 0) {
	// 	// 	query = GetMerchantInfoByEmailList([
	// 	// 		userProfile?.defaultEmail,
	// 	// 		...userProfile.emails,
	// 	// 	]);
	// 	// }
	// 	Logger.network(callerName, "query GetConsumerInfoByUserId", query);
	// 	try {
	// 		query &&
	// 			getDocs(query).then((resp) => {
	// 				Logger.network(callerName, "response GetConsumerInfoByUserId", resp);

	// 				let arr: DocumentData[] = [];
	// 				resp.forEach((doc) => {
	// 					Logger.log("Log GetConsumerInfoByUserId", doc.data(), doc);
	// 					arr.push(doc.data());
	// 				});
	// 				if (arr.length === 1) {
	// 					//UserProfile exists
	// 					setConsumerInfo(arr[0] as Consumer);

	// 					// } else if (arr.length === 0) {
	// 					// 	//No UserProfile exists
	// 					// 	pageSwap(PageTypes.NEW_CONSUMER);
	// 				} else {
	// 					//More than one UserProfile Exists
	// 					Logger.error(
	// 						callerName,
	// 						"GetUserProfileByUserId",
	// 						"returned too many UserProfiles",
	// 						arr,
	// 					);
	// 				}
	// 			});
	// 	} catch (err) {
	// 		Logger.error(callerName, "callToUpdateConsumerInfo", err);
	// 	}
	// };
	const setUProfile = (data: any) => setUserProfile(data);
	const callToUpdateUserProfile = (callerName: string) => {
		let query = GetUserProfileByUserId(user?.user?.uid);
		Logger.network(callerName, "query callToUpdateUserProfile", query);
		try {
			query &&
				getDocs(query).then((resp) => {
					Logger.network(callerName, "response callToUpdateUserProfile", resp);

					let arr: DocumentData[] = [];
					let docId = null;
					resp.forEach((doc) => {
						Logger.log("Log callToUpdateUserProfile", doc.data(), doc);
						docId = doc.id;
						arr.push(doc.data());
					});
					if (arr.length === 1) {
						//UserProfile exists
						setUProfile(arr[0]);
						if (
							arr[0].lastLoginDate !==
							new Date(window.Date.now()).toDateString()
						)
							updateLastLoginDate(docId, arr[0]);
					} else if (arr.length === 0) {
						//No UserProfile exists
						pageSwap(PageTypes.CREATE_PROFILE);
					} else {
						//More than one UserProfile Exists
						Logger.error(
							callerName,
							"GetUserProfileByUserId",
							"returned too many UserProfiles",
							arr,
						);
					}
				});
		} catch (err) {
			Logger.error(callerName, "GetUserProfileByUserId", err);
		}
	};
	const logout = () => {
		authutil.logOut();
		setUser(undefined);
		setUserProfile(undefined);
		setMerchantInfo(undefined);
		// setConsumerInfo(undefined);TODO:CONSUMER
		pageSwap(PageTypes.LANDING);
	};
	const setAppUser = (newUser: any) => {
		authutil.login(newUser);
		setUser(newUser);
	};
	const pageSwap = (newPage: string, optionalPageProps?: object) => {
		authutil.interact(logout);
		setPage({ pageName: newPage, pageSwapProps: optionalPageProps });
	};
	const loginClick = () => {
		Logger.log("login clicked");
		if (user) {
			//logout
			authutil.logOut();
		} else {
			//login
			Logger.log("login page swap");
			pageSwap(PageTypes.LOGIN);
		}
	};

	return (
		<div className='App'>
			<div className='app-container'>
				<Header
					loginClick={loginClick}
					user={user}
					logout={logout}
					pageSwap={pageSwap}
					merchantInfo={merchantInfo}
				/>
				<div className='page-container default-background-color'>
					<PageContainer
						setMerchantInfo={setMerchInfo}
						callToUpdateMerchantInfo={callToUpdateMerchantInfo}
						merchantInfo={merchantInfo}
						// setConsumerInfo={setConsumerInfo}
						// consumerInfo={consumerInfo}
						page={page}
						pageSwap={pageSwap}
						setAppUser={setAppUser}
						user={user}
						userProfile={userProfile}
						callToUpdateUserProfile={callToUpdateUserProfile}
						// callToUpdateConsumerInfo={callToUpdateConsumerInfo}
					/>
				</div>
				{/* footer */}
			</div>
		</div>
	);
}

export default App;
