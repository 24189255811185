export const PageTypes = {
    LANDING: "landing",
    MERCHANT_HOME: "merchant_home",
    CONSUMER_HOME: "consumer_home",
    LOGIN: "login",
    MERCHANT_CREATE_PERK: "merchant_create_perk",
    ABOUT: "about",
    PRIVACY: "privacy",
    TERMS: "terms",
    VIEW_PERKS: "view_perks",
    NEW_MERCHANT: "new_merchant",
    NEW_CONSUMER: "new_consumer",
    VIEW_PROFILE: "view_profile",
    CREATE_PROFILE: "create_profile",
}
