import React, { useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import PropTypes from "prop-types";
import "./CreatePerk.css";
import { addNewPerk, fireStoreDb } from "../../auth/firebase";
import { PageTypes } from "../../PageTypes";
import { Perk } from "../../models/Perk.type";
import * as Logger from "../../Utility/Logger";
import { Merchant } from "../../models/Merchant.type";
import { GeoPoint } from "firebase/firestore";

let markerPosition = {
	lat: 41.90476224706472,
	lng: 12.49822074385094,
};
let map: google.maps.Map, marker: any, perk_radius: google.maps.Circle;
function CreatePerk(props: any) {
	const mapZoom=12;
	const [mapCenter, setMapCenter] = useState(
		props.merchCoords || {
			lat: 41.83581,
			lng: -74.65425,
		},
	);
	const [finalEstimate, setFinalEstimate] = useState(4.99);
	const [perkTitle, setPerkTitle] = useState("");
	const [selectedMerchant, setSelectedMerchant] = useState<Merchant>(
		props.preselectedMerchant || props.merchantInfo[0] || null,
	);
	const [perkMessage, setPerkMessage] = useState("");
	const [perkDate, setPerkDate] = useState("");
	const [perkExpiration, setPerkExpiration] = useState(1);
	const [perkRadius, setPerkRadius] = useState(2); //1-10 miles
	const gmapId = "5887eb4119999348";

	const handlePerkRadiusChange = (newRadius: number) => {
		Logger.log("map", map, "newRadius", newRadius, "marker", marker);
		// let z = map?.getZoom();
		// z && setMapZoom(z);
		// let c = map?.getCenter();
		// z && setMapCenter(c);
		// Logger.log("z", z, "c", c);
		// if (marker) markerPosition = marker.position;
		perk_radius?.setRadius(newRadius * 1609.34);
		setPerkRadius(newRadius);
	};
	useEffect(() => {
		initMap();
	}, []);

	async function initMap(): Promise<void> {
		// Request needed libraries.
		//@ts-ignore
		const { Map } = (await google.maps.importLibrary(
			"maps",
		)) as google.maps.MapsLibrary;
		const { AdvancedMarkerElement } = (await google.maps.importLibrary(
			"marker",
		)) as google.maps.MarkerLibrary;
		map = new Map(document.getElementById("map") as HTMLElement, {
			zoom: mapZoom,
			center: mapCenter,
			mapId: gmapId,
			mapTypeId: "roadmap",
			disableDefaultUI: true,
			zoomControl: true,
			gestureHandling: "auto",
			disableDoubleClickZoom: true,
		});
		if (markerPosition) {
			marker = new AdvancedMarkerElement({
				gmpDraggable: true,
				map: map,
				position: markerPosition,
				title: "Perk Location",
			});
			perk_radius = new google.maps.Circle({
				map,
				strokeColor: "rgb(5, 7, 9)",
				strokeOpacity: 0.75,
				strokeWeight: 2,
				fillColor: "rgb(69, 249, 29)",
				fillOpacity: 0.18,
				center: markerPosition,
				radius: perkRadius * 1609.34, //miles to meters conversion
			});
		}
		google.maps.event.addListener(marker, "dragend", function (e: any) {
			markerPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
			// Logger.log("marker drag", markerPosition);
			perk_radius.setCenter(markerPosition);
		});
		// google.maps.event.addListener(map, "drag", (e: any) => console.log("e", e));
		google.maps.event.addListener(map, "dblclick", function (e: any) {
			markerPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
			Logger.log("Double Click", markerPosition);
			if (marker) {
				marker.setMap(null);
				perk_radius.setMap(null);
			}
			marker = new AdvancedMarkerElement({
				gmpDraggable: true,
				map: map,
				position: markerPosition,
				title: "Perk Location",
			});
			perk_radius = new google.maps.Circle({
				map,
				draggable: true,
				clickable: false,
				strokeColor: "rgb(5, 7, 9)",
				strokeOpacity: 0.75,
				strokeWeight: 2,
				fillColor: "rgb(69, 249, 29)",
				fillOpacity: 0.18,
				center: markerPosition,
				radius: perkRadius * 1609.34, //miles to meters conversion
			});
		});
	}

	const submitPerk = () => {
		let creationDate = new Date(window.Date.now()).toISOString();
		let expireDate = new Date(
			window.Date.parse(perkDate) + perkExpiration * 24 * 3600000,
		);

		Logger.log("submitting perk at location:", markerPosition);
		let perk: Perk = {
			perkId: perkTitle + "-" + creationDate,
			createdBy: props.uid,
			merchantId: selectedMerchant.merchantId,
			perkTitle: perkTitle,
			perkMessage: perkMessage,
			perkDate: perkDate,
			perkExpiration: expireDate.toDateString(),
			perkRadius: perkRadius, //miles
			perkCenter: new GeoPoint(markerPosition.lat, markerPosition.lng),
			finalEstimate: finalEstimate,
			creationDate: creationDate,
		};
		Logger.log("Send this to our DB", props.user, perk);
		if (window.confirm("Are you sure you'd like to submit this perk?")) {
			addNewPerk(perk, PageTypes.VIEW_PERKS, props.pageSwap);
		}
	};

	// initMap();
	return (
		<div className='create-perk'>
			<h1 className='page-title'>Perk-O-Later</h1>
			<h3 className='cen'>Create Your Perks Here</h3>
			<p className='cen'>
				Perks are app notifications that will be available to users near your
				location.
			</p>

			<form id='submitperkform'>
				<fieldset id='perk-content' className='perk'>
					<p className='b'>Perk Content</p>
					<label htmlFor='perkTitle'>Perk title</label>
					<input
						id='perk_title'
						type='text'
						required
						autoFocus
						maxLength={50}
						name='perkTitle'
						placeholder='Perk title (50 chars max)'
						onChange={(event) => setPerkTitle(event.target.value)}
					/>
					{props.merchantInfo.length > 1 && (
						<>
							<label htmlFor='selectMerchant'>Select Merchant</label>
							<select
								name='selectMerchant'
								id='perk_expiration'
								onChange={(event) => {
									let selected = props.merchantInfo.find(
										(merch: Merchant) =>
											merch.merchantName === event.target.value,
									);
									Logger.log("CreatePerk setting selectedMerchant", selected);
									setSelectedMerchant(selected);
								}}
								defaultValue={selectedMerchant.merchantName}
							>
								{props.merchantInfo.map((merchant: Merchant, index: number) => {
									// Logger.log("CreatePerk MerchantList", merchant);
									return (
										<option
											key={merchant.merchantName + index}
											value={merchant.merchantName}
										>
											{merchant.merchantName}
										</option>
									);
								})}
							</select>
						</>
					)}
					<label htmlFor='perkMessage'>Perk message</label>
					<textarea
						id='perk_message'
						maxLength={150}
						name='perkMessage'
						placeholder='Perk message (150 chars max)'
						required
						onChange={(event) => setPerkMessage(event.target.value)}
					></textarea>
				</fieldset>

				<fieldset className='perk' id='perk-options'>
					<p className='b'>Perk Options</p>
					<label htmlFor='perkDate'>Perk date</label>
					<input
						type='date'
						id='perk_date'
						name='perkDate'
						defaultValue=''
						onChange={(event) => setPerkDate(event.target.value)}
					/>

					<label htmlFor='perkExpiration'>Perk Expiration:</label>
					<select
						name='perkExpiration'
						id='perk_expiration'
						onChange={(event) =>
							setPerkExpiration(JSON.parse(event.target.value))
						}
					>
						<option value={1}>1 Day</option>
						<option value={3}>3 Days</option>
						<option value={7}>7 Days</option>
					</select>

					<label htmlFor='perkRadius'>Perk radius / "geofence"</label>
					<input
						type='range'
						id='slider'
						name='perkRadius'
						list='radius'
						min={1}
						max={10}
						defaultValue={2}
						step={1}
						onChange={(event) =>
							handlePerkRadiusChange(Number(event.target.value))
						}
					/>
					<datalist id='radius'>
						<option value={1} label='1'></option>
						<option value={2} label='2'></option>
						<option value={3} label='3'></option>
						<option value={4} label='4'></option>
						<option value={5} label='5'></option>
						<option value={6} label='6'></option>
						<option value={7} label='7'></option>
						<option value={8} label='8'></option>
						<option value={9} label='9'></option>
						<option value={10} label='10'></option>
					</datalist>

					<p className='b' id='finalreview'>
						Final Estimate <mark id='finalcost'>{finalEstimate}</mark>
					</p>
					<div id='map'></div>
				</fieldset>

				<fieldset id='perk-send'>
					<p className='b'>Perk Preview</p>
					<p>App users will see this perk when they enter your geofence.</p>
					<aside>
						<cite>Yayperks &middot; Now</cite>
						<h6></h6>
						<p></p>
					</aside>
					<input
						id='submitperk'
						type='button'
						className='button'
						value='Create this Perk'
						onClick={() => submitPerk()}
					/>
				</fieldset>
			</form>
		</div>
	);
}

CreatePerk.propTypes = {
	uid: PropTypes.string,
	pageSwap: PropTypes.func,
	merchantInfo: PropTypes.array,
	merchantCoords: PropTypes.object,
	preselectedMerchant: PropTypes.object,
};

export default CreatePerk;
