// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/firestore';
import { auth } from 'firebaseui';
import { collection, query, where, getDocs, QuerySnapshot, addDoc, doc, setDoc } from "firebase/firestore";
import './firebaseUI.css';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import * as Logger from "../Utility/Logger";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBu4Cuf0Fmpqp-1oGisO78pw_f3SpNKRy8",
    authDomain: "yay-perks.firebaseapp.com",
    databaseURL: "https://yay-perks-default-rtdb.firebaseio.com",
    projectId: "yay-perks",
    storageBucket: "yay-perks.appspot.com",
    messagingSenderId: "97734557626",
    appId: "1:97734557626:web:aa008d117366fe8602a783",
    measurementId: "G-GN7GSZ6YQR",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const fireStoreDb = getFirestore(app);
// export const analytics = getAnalytics(app);

// Initialize the FirebaseUI Widget using Firebase.
const authorization = firebase.auth();
export let ui = new auth.AuthUI(authorization);

export const addNewPerk = (perk, newPage, pageSwap) => {
    if (perk) {
        Logger.network("Adding perk", perk);
        try {
            addDoc(collection(fireStoreDb, "perks"), perk).then((docRef) => {
                Logger.log("Perk Document written with ID: ", docRef.id);
                window.alert("Thank you!");
                pageSwap(newPage);
            });
        } catch (error) {
            Logger.error("Problem adding new perk", error);
            window.alert("Issue creating this perk");
        }
    }
};
export const addNewConsumerProfile = (consumerProfile, newPage, pageSwap) => {
    if (consumerProfile) {
        Logger.network("Adding consumerProfile", consumerProfile);
        try {
            addDoc(collection(fireStoreDb, "ConsumerProfiles"), consumerProfile).then((docRef) => {
                Logger.log("consumerProfile Document written with ID: ", docRef.id);
                window.alert("Thank you!");
                pageSwap(newPage);
            });
        } catch (error) {
            Logger.error("Problem adding new consumerProfile", error);
            window.alert("Issue creating this ConsumerProfile");
        }
    }
};
export const addNewUserProfile = (userProfile, newPage, pageSwap) => {
    if (userProfile) {
        Logger.network("Adding userProfile", userProfile);

        try {
            addDoc(collection(fireStoreDb, "userProfiles"), userProfile).then((docRef) => {
                Logger.log("UserProfile Document written with ID: ", docRef.id);
                window.alert("Thank you!");
                pageSwap(newPage);
            });
        } catch (error) {
            Logger.error("Problem adding new userProfile", error);
            window.alert("Issue creating this UserProfile");
        }
    }
};
export const updateLastLoginDate = (documentId, userProfile) => {
    Logger.network("updateLastLoginDate");
    if (documentId && userProfile) {
        try {
            let newData = { ...userProfile, lastLoginDate: new Date(window.Date.now()).toDateString() };
            console.log("newData", newData);
            setDoc(doc(fireStoreDb, "userProfiles", documentId), newData).then(() => {
                Logger.log("UserProfile updateLastLoginDate");
            });
        } catch (error) {
            Logger.error("Problem updating lastLoginDate", error);
        }
    }
};
export const updateMerchantByMerchantId = (merchantId, newData) => {
    Logger.network("updateMerchantByMerchantId");
    if (merchantId && newData) {
        try {
            console.log("newData", newData);
            setDoc(doc(fireStoreDb, "merchants", updateMerchantByMerchantId), newData).then(() => {
                Logger.log("updateMerchantByMerchantId complete");
            });
        } catch (error) {
            Logger.error("Problem updating updateMerchantByMerchantId", error);
        }
    }
};
export const GetPerksByMerchantIdList = (merchantIdList) => {
    if (merchantIdList)
        return query(
            collection(fireStoreDb, "perks"),
            where("merchantId", "in", merchantIdList),
        );
    else
        return false;
};
export const GetPerksByCreatedBy = (uid) => {
    if (uid)
        return query(
            collection(fireStoreDb, "perks"),
            where("createdBy", "==", uid),
        );
    else
        return false;
};
export const GetMerchantInfoByEmail = (email) => {
    if (email)
        return query(
            collection(fireStoreDb, "merchants"),
            where("accountManagers", "array-contains", email),
        );
    else
        return false;
};
export const GetMerchantInfoByEmailList = (emails) => {
    if (emails)
        return query(
            collection(fireStoreDb, "merchants"),
            where("accountManagers", "array-contains-any", emails),
        );
    else
        return false;
};
export const GetUserProfileByUserId = (uid) => {
    if (uid)
        return query(
            collection(fireStoreDb, "userProfiles"),
            where("uid", "==", uid),
        );
    else
        return false;

};
export const GetConsumerInfoByUserId = (uid) => {
    if (uid)
        return query(
            collection(fireStoreDb, "consumerProfiles"),
            where("uid", "==", uid),
        );
    else
        return false;

};

