import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Merchant } from "../../models/Merchant.type";
import * as Logger from "../../Utility/Logger";
import "./MerchantHome.css";
import { PageTypes } from "../../PageTypes";

function MerchantHome(props: any) {
	const [editMerchant, setEditMerchant] = useState("");
	const [expandedMerchant, setExpandedMerchant] = useState("");

	const [merchantPhone, setMerchantPhone] = useState("");
	const [merchantUrl, setMerchantUrl] = useState("");
	const [merchantSlogin, setMerchantSlogin] = useState("");
	const [merchantAddressLine1, setMerchantAddressLine1] = useState("");
	const [merchantAddressLine2, setMerchantAddressLine2] = useState("");
	const [merchantAddressCity, setMerchantAddressCity] = useState("");
	const [merchantAddressState, setMerchantAddressState] = useState("");
	const [merchantAddressZip, setMerchantAddressZip] = useState("");
	const [accountManagers, setAccountManagers] = useState<Array<string>>([]);
	const [removedManagers, setRemovedManagers] = useState<Array<string>>([]);
	const [singleManager, setSingleManager] = useState<string>();

	useEffect(() => {
		Logger.log("MerchantHome page - uid?:", props.uid);
		if (props.uid) {
			props.callToUpdateMerchantInfo("MerchantHome");
		}
	}, []);
	const saveMerchantChange = (merchantId: string) => {
		// let merchantData = updateMerchantByMerchantId(merchantId);
		setEditMerchant("");
	};
	const editMerchantClick = (merchant: Merchant) => {
		setMerchantPhone(merchant.merchantPhone);
		setMerchantUrl(merchant.merchantUrl);
		setMerchantAddressLine1(merchant.merchantAddress.merchantAddressLine1);
		setMerchantAddressLine2(merchant.merchantAddress.merchantAddressLine2);
		setMerchantAddressCity(merchant.merchantAddress.merchantAddressCity);
		setMerchantAddressState(merchant.merchantAddress.merchantAddressState);
		setMerchantAddressZip(merchant.merchantAddress.merchantAddressZip);
		setAccountManagers(merchant.accountManagers);
		setRemovedManagers([]);
		setMerchantSlogin(merchant.merchantSlogin);

		setEditMerchant(merchant.merchantId);
	};
	const removeAccountManager = (index: number) => {
		let newArray = [];
		setRemovedManagers([...removedManagers, accountManagers[index]]);
		if (accountManagers.length > 1) {
			for (let i = 0; i < accountManagers.length; i++) {
				if (i != index) newArray.push(accountManagers[i]);
			}
			setAccountManagers(newArray);
		}
	};
	const merchantClick = (merchant: Merchant) => {
		Logger.log("MerchantHome", "merchantClick", merchant);

		let hide = document.getElementById(
			"merchant-hideable-div-" + expandedMerchant,
		);
		if (hide) {
			hide.className += " hidden";
		}
		let show = document.getElementById(
			"merchant-hideable-div-" + merchant.merchantId,
		);
		if (merchant.merchantId !== expandedMerchant) {
			setExpandedMerchant(merchant.merchantId);
			if (show) show.className = show.className.replace(" hidden", "");
		} else {
			setExpandedMerchant("");
		}
	};
	const addAccountManager = (email: string) => {
		let newArray = Array.from(accountManagers);
		//TODO: matches email regex
		accountManagers.find((account) => email === account)
			? window.alert("That email already exists")
			: newArray.push(email);
		setSingleManager(undefined);
		Logger.log("NewMerchant settingAccountManagers to", newArray);
		setAccountManagers(newArray);
	};
	const addRemovedManager = (email: string, removedManagerIndex: number) => {
		setRemovedManagers(
			removedManagers.filter((v, i) => i != removedManagerIndex),
		);
		addAccountManager(email);
	};
	const addPerk = (merch: Merchant) => {
		props.pageSwap(PageTypes.MERCHANT_CREATE_PERK, {
			preselectedMerchant: merch,
		});
	};
	return (
		<>
			<h1 className="merchant-title page-title">Merchant Info</h1>
			{props.merchantInfo && (
				<div className='merchant-list-box' id='merchant-list-box'>
					{props.merchantInfo.map((merchant: Merchant, index: number) => {
						return (
							<div
								key={"merchant-" + index}
								id='merchant-list-item'
								className='merchant-list-item'
							>
								<h2
									key={"merchant-name-" + index}
									onClick={() => merchantClick(merchant)}
									className='merchant-name'
								>
									{merchant.merchantName}
									{expandedMerchant === merchant.merchantId ? (
										<img className='up-arrow' src='./up-arrow.svg' />
									) : (
										<img className='down-arrow' src='./up-arrow.svg' />
									)}
								</h2>
								<div
									id={"merchant-hideable-div-" + merchant.merchantId}
									className=' hidden'
								>
									<div className='hidden-div'>
										{editMerchant === merchant.merchantId ? (
											<>
												<button
													type='button'
													className='merchant-list-item-editsave-button'
													onClick={() =>
														saveMerchantChange(merchant.merchantId)
													}
												>
													Save
												</button>
												<form id='submitupdatemerchantform'>
													<fieldset id='merchant-info'>
														<label htmlFor='merchantPhone'>Phone Number</label>
														<input
															id='merchant_phone'
															type='text'
															autoFocus
															maxLength={50}
															name='merchantPhone'
															placeholder='Merchant Phone Number (50 chars max)'
															value={merchantPhone}
															onChange={(event) =>
																setMerchantPhone(event.target.value)
															}
														/>
														<label htmlFor='merchantUrl'>Website Address</label>
														<input
															id='merchant_url'
															type='text'
															maxLength={100}
															name='merchantUrl'
															placeholder='Merchant Website Address (100 chars max)'
															value={merchantUrl}
															onChange={(event) =>
																setMerchantUrl(event.target.value)
															}
														/>
														<label htmlFor='merchant_slogin'>
															Merchant Slogin
														</label>
														<textarea
															id='merchant_slogin'
															maxLength={150}
															name='merchantSlogin'
															placeholder='Merchant Slogin (150 chars max)'
															value={merchantSlogin}
															onChange={(event) =>
																setMerchantSlogin(event.target.value)
															}
														></textarea>

														<label htmlFor='merchant_address_line1'>
															Physical Address
														</label>
														<input
															id='merchant_address_line1'
															type='text'
															maxLength={80}
															name='merchantAddress'
															placeholder='Address Line 1'
															value={merchantAddressLine1}
															onChange={(event) =>
																setMerchantAddressLine1(event.target.value)
															}
														/>
														<input
															id='merchant_address_line2'
															type='text'
															maxLength={80}
															name='merchantAddressLine2'
															placeholder='Address Line 2'
															value={merchantAddressLine2}
															onChange={(event) =>
																setMerchantAddressLine2(event.target.value)
															}
														/>
														<input
															id='merchant_address_city'
															type='text'
															maxLength={25}
															name='merchantAddressCity'
															placeholder='Hawley'
															value={merchantAddressCity}
															onChange={(event) =>
																setMerchantAddressCity(event.target.value)
															}
														/>
														<input
															id='merchant_address_state'
															type='text'
															maxLength={2}
															name='merchantAddressState'
															placeholder='PA'
															value={merchantAddressState}
															onChange={(event) =>
																setMerchantAddressState(event.target.value)
															}
														/>
														<input
															id='merchant_address_zip'
															type='text'
															maxLength={100}
															name='merchantAddressZipCode'
															placeholder='18428'
															value={merchantAddressZip}
															onChange={(event) =>
																setMerchantAddressZip(event.target.value)
															}
														/>
													</fieldset>

													<label htmlFor='merchantAccountManagers'>
														Add Email(s) for Account Managers
													</label>
													<div className='accountManager-container'>
														{accountManagers?.map((ac, index: number) => {
															return (
																<p
																	className='small-pill'
																	key={"accountManager" + index}
																>
																	{ac}
																	{accountManagers.length > 1 && (
																		<button
																			type='button'
																			onClick={() =>
																				removeAccountManager(index)
																			}
																		>
																			X
																		</button>
																	)}
																</p>
															);
														})}
														{removedManagers?.map((ac, index: number) => {
															return (
																<p
																	className='small-pill-red'
																	key={"accountManager" + index}
																>
																	{ac}

																	<button
																		type='button'
																		onClick={() => addRemovedManager(ac, index)}
																	>
																		+
																	</button>
																</p>
															);
														})}
													</div>
													<div className='singleAccountManager-row'>
														<input
															id='merchant_accountManagers'
															type='email'
															name='merchantAccountManagers'
															placeholder='Add 1 Email Address at a time'
															onChange={(event) =>
																setSingleManager(event.target.value)
															}
														/>
														<button
															type='button'
															className='add-single-account-manager'
															onClick={() =>
																singleManager &&
																addAccountManager(singleManager)
															}
														>
															add
														</button>
													</div>
												</form>
											</>
										) : (
											//View this Merchant
											<>
												<button
													type='button'
													className='merchant-list-item-editsave-button button'
													onClick={() => editMerchantClick(merchant)}
												>
													Edit
												</button>
												<button className='button' onClick={() => addPerk(merchant)}>
													Create a New Perk
												</button>

												{merchant.merchantPhone && (
													<h3>{merchant.merchantPhone}</h3>
												)}
												{merchant.merchantUrl && (
													<h3>{merchant.merchantUrl}</h3>
												)}
												{merchant.merchantSlogin && (
													<h3>{merchant.merchantSlogin}</h3>
												)}
												{merchant.merchantAddress && (
													<>
														<h3 key='merchant-address-line1'>
															{merchant.merchantAddress.merchantAddressLine1}
														</h3>
														<h3 key='merchant-address-line2'>
															{merchant.merchantAddress.merchantAddressLine2}
														</h3>
														<h3 key='merchant-address-city-state-zip'>
															{merchant.merchantAddress.merchantAddressCity},{" "}
															{merchant.merchantAddress.merchantAddressState}{" "}
															{merchant.merchantAddress.merchantAddressZip}
														</h3>
													</>
												)}
												<h2>Account Managers</h2>
												{merchant.accountManagers &&
													merchant.accountManagers.map((manager: string) => {
														return (
															<>
																<p key={"accountmanager-" + manager}>
																	{manager}
																</p>
															</>
														);
													})}
											</>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}
			{!props.merchantInfo && (
				<h2>Whoops! Looks like you don't have any Merchant Info...</h2>
			)}
		</>
	);
}

MerchantHome.propTypes = {
	merchantInfo: PropTypes.array,
	pageSwap: PropTypes.func,
	uid: PropTypes.string,
	callToUpdateMerchantInfo: PropTypes.func,
};

export default MerchantHome;
