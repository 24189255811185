import React from "react";
import PropTypes from "prop-types";

function About(props: any) {
	return (
		<>
			<h2 className="cen">About</h2>
			<h3 id='what'>What is Yay Perks?</h3>
			<p>Yayperks is a hyperlocal, promotional app &amp; business service.</p>
			<p id='mission'>
				Our mission is to create a service that provides small businesses with a
				simple and economical way to target consumers in the vicinity of their
				business.
			</p>
			<h3 id='what'>What&rsquo;s a perk?</h3>
			<p>
				A perk is a deal or promotion sent by a merchant for use exclusively at
				their place of business for a specified period of time. A perk is sent
				in the form of an app notification offering you a deal for their
				products or service.
			</p>
			<h3 id='works'>How it Works</h3>
			<p>
				A user simply downloads the free Yayperks mobile app from the Google
				Play, or Apple App Store, and registers. Once registered, the user will
				immediately be eligible to start receiving perks.
			</p>
			<p>
				Participaing merchants schedule perks. Perks are active for a specified
				time period in a specific perimeter around a merchant&rsquo;s physical
				location (called a &#8220;geofence&#8221;).
			</p>
			<p>
				When an app user enters a merchant&rsquo;s geofence they will receive an
				app notification detailing the perk. Users then present the notification
				to the merchant&rsquo;s staff, and redeem the perk.
			</p>
			<h3 id='who'>Who operates Yayperks?</h3>
			<p>
				Yayperks LLC is a veteran&ndash;owned &amp; operated, New
				Jersey&ndash;based company. 🇺🇸
			</p>
		</>
	);
}

About.propTypes = { pageSwap: PropTypes.func, user: PropTypes.object };

export default About;
