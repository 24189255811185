import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PageTypes } from "../../PageTypes";
import {
  GetPerksByCreatedBy,
  GetPerksByMerchantIdList,
} from "../../auth/firebase";
import { getDocs } from "firebase/firestore";
import * as Logger from "../../Utility/Logger";
import "./ViewPerks.css";

function ViewPerks(props) {
  const [perks, setPerks] = useState(false);

  useEffect(() => {
    let merchantIdList =
      props.merchantInfo &&
      Array.from(
        props.merchantInfo.map((merch) => {
          return merch.merchantId;
        })
      );
    let query = GetPerksByMerchantIdList(merchantIdList);
    query &&
      getDocs(query).then((resp) => {
        let arr = [];
        resp.forEach((doc) => arr.push(doc.data()));
        setPerks(arr);
        Logger.network(
          "ViewPerks:",
          "query",
          "GetPerksByMerchantIdList",
          "response",
          arr
        );
      });
  }, [props.merchantInfo]);

  const perkClick = (perk) => {
    Logger.log("ViewPerks", "perkClick", perk);
    let hide = document.getElementById("perk-hideable-div-" + perk.perkId);
    if (hide.className.includes("hidden")) {
      hide.className = hide.className.replace(" hidden", "");
    } else {
      hide.className += " hidden";
    }
  };
  // Logger.warn("perks", perks);
  return (
    <div className="viewperks">
      <button
        className="navTo-create-perk-button button"
        onClick={() => props.pageSwap(PageTypes.MERCHANT_CREATE_PERK)}
      >
        Create a New Perk
      </button>
      <h1 className="page-title">Existing Perks</h1>
      {props.merchantInfo &&
        props.merchantInfo.length > 0 &&
        props.merchantInfo.map((merchant, index) => {
          return (
            <div key={merchant + index} className="merchant-group">
              <h2>{merchant.merchantName}</h2>
              {perks && perks.length > 0 && (
                <div className="perks-box">
                  {perks.filter(
                    (perk) => perk.merchantId == merchant.merchantId
                  ).length > 0 ? (
                    perks
                      .filter((perk) => perk.merchantId == merchant.merchantId)
                      .map((perk, index) => {
                        return (
                          <React.Fragment key={"perk-" + index}>
                            <button
                              type="button"
                              onClick={() => perkClick(perk)}
                              className="viewperks-single-perk-button"
                            >
                              <h1>{perk.perkTitle}</h1>
                              <p>{perk.perkMessage}</p>
                            </button>
                            <div
                              className="hideable-div hidden"
                              id={"perk-hideable-div-" + perk.perkId}
                            >
                              <h4>
                                {perk.perkDate + " to " + perk.perkExpiration}
                              </h4>
                              <h3>${perk.finalEstimate}</h3>
                            </div>
                          </React.Fragment>
                        );
                      })
                  ) : (
                    <h3>
                      Whoops, looks like there's no perks for this merchant...
                      <button
                        className="navTo-create-perk-button button"
                        onClick={() =>
                          props.pageSwap(PageTypes.MERCHANT_CREATE_PERK)
                        }
                      >
                        Create a New Perk
                      </button>
                    </h3>
                  )}
                </div>
              )}
            </div>
          );
        })}
      {/* {perks && perks.length > 0 && <div className="perks-box">
				{perks.map((perk, index) => {
					return (<React.Fragment key={"perk-" + index}>
						<button type="button" onClick={() => perkClick(perk)} className="viewperks-single-perk-button">
							<h1>{perk.perkTitle}</h1>
							<p>{perk.perkMessage}</p>
						</button>
						<div className="hideable-div hidden" id={"perk-hideable-div-" + perk.perkId}>
							<h4>{perk.perkDate + " to " + perk.perkExpiration}</h4>
							<h3>${perk.finalEstimate}</h3>
						</div>
					</React.Fragment>);
				})}
			</div>} */}
      {!(perks && perks.length > 0) && (
        <h2>Whoops! Looks like you don't have any perks...</h2>
      )}
    </div>
  );
}

ViewPerks.propTypes = {
  pageSwap: PropTypes.func,
  merchantInfo: PropTypes.array,
  uid: PropTypes.string,
};

export default ViewPerks;
