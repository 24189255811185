import React from "react";
import PropTypes from "prop-types";

function Privacy(props: any) {
	return (

    <>
    <h2 className="cen">Privacy Policy</h2>
    <p className="cen effective">Effective: September 1, 2023</p>

    <p>Privacy is important, and we will respect yours. HTTPS has been enabled across the family of Yayperks apps. All data is stored in Google&rsquo;s secure data centers.</p>

    <p>With the exception of <a rel="noopener noreferrer" target="_blank" href="https://www.google.com/analytics/">Google Analytics</a> and payment widgets on the merchant pages, Yayperks does not serve any other third&ndash;party content on the site.</p>

    <h3>Data collection</h3>
    <p>We collect your device id. We also may aggregate and store associations between your device id and associated promotions &amp; timestamps sent to your device.</p>

    <h3>Merchant / User Privacy</h3>
    <p>Merchants are not provided device ids and are not able to associate a device id to a promotion through our service.</p>

    <p>Yayperks utilizes <a rel="noopener noreferrer" target="_blank" href="https://stripe.com/">Stripe</a> to process merchant payments. View <a rel="noopener noreferrer" target="_blank" href="https://stripe.com/us/legal">their privacy policy and terms of service</a>.</p>

    <h3>How We Share Your Information</h3>
    <p>In short, we don&rsquo;t. In the event of a corporate divestiture, merger, or dissolution, user data are considered to be transferrable business assets.</p>

    <h3>Cookies / Browser Storage</h3>
    <p>All other cookies are set by Google Analytics or Stripe, including, but not limited to: <code>_ga</code> and <code>_gid</code>, <code>__stripe_mid</code>.</p>

    <h3>Law and Harm</h3>
    <p>We may preserve or disclose the information collected if we believe that it is reasonably necessary to comply with a law, regulation or legal request; to protect the safety of any person; to address fraud, security or technical issues; or to protect Yayperks&rsquo; rights, business or property.</p>

    <h3>Changes to Our Privacy Policy</h3>
    <p>If we change our privacy policy, we will post those changes on this page to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it. Changes to this Privacy Policy are effective on the date listed at the top of the page.</p>

    <h3>Privacy Contact Information</h3>
    <p>If you notice a privacy issue, please report it to: support @ yayperks.com</p></>
	);
}

Privacy.propTypes = { pageSwap: PropTypes.func, user: PropTypes.object };

export default Privacy;
