import React, { useState } from "react";
import PropTypes from "prop-types";
import { addDoc, collection, doc, GeoPoint, setDoc } from "firebase/firestore";
import "./NewMerchant.css";
import { fireStoreDb } from "../../auth/firebase";
import { PageTypes } from "../../PageTypes";
import { Merchant } from "../../models/Merchant.type";
import * as Logger from "../../Utility/Logger";

function NewMerchant(props: any) {
	const [merchantName, setMerchantName] = useState("");
	const [merchantPlan, setMerchantPlan] = useState("");
	const [merchantPhone, setMerchantPhone] = useState("");
	const [merchantUrl, setMerchantUrl] = useState("");
	const [merchantSlogin, setMerchantSlogin] = useState("");
	const [merchantCoords, setMerchantCoords] = useState(undefined);
	const [ccName, setCcName] = useState("");
	const [ccNumber, setCcNumber] = useState("");
	const [ccExpiration, setCcExpiration] = useState("");
	const [ccCvc, setCcCvc] = useState("");
	const [merchantAddressLine1, setMertchantAddressLine1] = useState("");
	const [merchantAddressLine2, setMertchantAddressLine2] = useState("");
	const [merchantAddressCity, setMertchantAddressCity] = useState("");
	const [merchantAddressState, setMertchantAddressState] = useState("");
	const [merchantAddressZip, setMertchantAddressZip] = useState("");
	const [accountManagers, setAccountManagers] = useState<Array<string>>([
		props.email,
	]);
	const [removedManagers, setRemovedManagers] = useState<Array<string>>([]);
	const [singleManager, setSingleManager] = useState<string>();

	const [mapZoom, setMapZoom] = useState(12);
	const gmapId = "5887eb4119999348";

	const removeAccountManager = (index: number) => {
		let newArray = [];
		setRemovedManagers([...removedManagers, accountManagers[index]]);
		if (accountManagers.length > 1) {
			for (let i = 0; i < accountManagers.length; i++) {
				if (i != index) newArray.push(accountManagers[i]);
			}
			setAccountManagers(newArray);
		}
	};
	const addRemovedManager = (email: string, removedManagerIndex: number) => {
		setRemovedManagers(
			removedManagers.filter((v, i) => i != removedManagerIndex),
		);
		addAccountManager(email);
	};
	const addAccountManager = (email: string) => {
		let newArray = Array.from(accountManagers);
		//TODO: matches email regex
		accountManagers.find((account) => email === account)
			? window.alert("That email already exists")
			: newArray.push(email);
		setSingleManager(undefined);
		Logger.log("NewMerchant settingAccountManagers to", newArray);
		setAccountManagers(newArray);
	};
	const submitMerchant = () => {
		let currentDate = new Date(window.Date.now()).toISOString();
		let merchant: Merchant = {
			merchantId: merchantName + "-" + currentDate,
			merchantOwner: props.uid,
			merchantCoords: merchantCoords,
			merchantPlan: merchantPlan,
			merchantPhone: merchantPhone,
			merchantName: merchantName,
			merchantSlogin: merchantSlogin,
			merchantUrl: merchantUrl,
			paymentInfo: {
				ccName: ccName,
				ccNumber: ccNumber,
				ccExpiration: ccExpiration,
				ccCvc: ccCvc,
			},
			merchantAddress: {
				merchantAddressLine1: merchantAddressLine1,
				merchantAddressLine2: merchantAddressLine2,
				merchantAddressCity: merchantAddressCity,
				merchantAddressState: merchantAddressState,
				merchantAddressZip: merchantAddressZip,
			},
			joinDate: currentDate, //
			accountManagers: accountManagers,
		};
		Logger.log("Send this to our DB", props.user, merchant);
		if (window.confirm("Have you double checked your info?")) {
			try {
				addDoc(collection(fireStoreDb, "merchants"), merchant).then(
					(docRef) => {
						Logger.log("Document written with ID: ", docRef.id);
						window.alert("Thank you!");
						// props.setMerchantInfo(merchant);
						props.pageSwap(PageTypes.MERCHANT_HOME);
					},
				);
			} catch (error) {
				Logger.error("Problem adding merchant", error);
				window.alert("Issue creating this merchant");
			}
		}
	};
	return (
		<div className='new-merchant'>
			<h1 className='page-title'>Create a Merchant Account</h1>
			<p className='center'>
				This account will allow you to make Perks. Perks are app notifications
				that will be available to users near your location.
			</p>

			<form id='submitnewmerchantform'>
				<fieldset id='merchant-info' className='merchant'>
					<p className='b'>Merchant Info</p>
					<label htmlFor='merchantName'>Merchant Name</label>
					<input
						id='merchant_name'
						type='text'
						maxLength={50}
						name='merchantName'
						placeholder='Merchant Name (50 chars max)'
						onChange={(event) => setMerchantName(event.target.value)}
					/>
					<label htmlFor='merchantPhone'>Phone Number</label>
					<input
						id='merchant_phone'
						type='text'
						maxLength={50}
						name='merchantPhone'
						placeholder='Merchant Phone Number (50 chars max)'
						onChange={(event) => setMerchantPhone(event.target.value)}
					/>
					<label htmlFor='merchantUrl'>Website Address</label>
					<input
						id='merchant_url'
						type='text'
						maxLength={100}
						name='merchantUrl'
						placeholder='Merchant Website Address (100 chars max)'
						onChange={(event) => setMerchantUrl(event.target.value)}
					/>
					<label htmlFor='perkMessage'>Merchant Slogin</label>
					<textarea
						id='merchant_slogin'
						maxLength={150}
						name='merchantSlogin'
						placeholder='Merchant Slogin (150 chars max)'
						onChange={(event) => setMerchantSlogin(event.target.value)}
					></textarea>

					<label htmlFor='merchant_address_line1'>Physical Address</label>
					<input
						id='merchant_address_line1'
						type='text'
						maxLength={80}
						name='merchantAddress'
						placeholder='Address Line 1'
						onChange={(event) => setMertchantAddressLine1(event.target.value)}
					/>
					<input
						id='merchant_address_line2'
						type='text'
						maxLength={80}
						name='merchantAddressLine2'
						placeholder='Address Line 2'
						onChange={(event) => setMertchantAddressLine2(event.target.value)}
					/>
					<input
						id='merchant_address_city'
						type='text'
						maxLength={25}
						name='merchantAddressCity'
						placeholder='Hawley'
						onChange={(event) => setMertchantAddressCity(event.target.value)}
					/>
					<input
						id='merchant_address_state'
						type='text'
						maxLength={2}
						name='merchantAddressState'
						placeholder='PA'
						onChange={(event) => setMertchantAddressState(event.target.value)}
					/>
					<input
						id='merchant_address_zip'
						type='text'
						maxLength={100}
						name='merchantAddressZipCode'
						placeholder='18428'
						onChange={(event) => setMertchantAddressZip(event.target.value)}
					/>
					<label htmlFor='merchantAccountManagers'>
						Add Email(s) for Account Managers
					</label>
					<div className='accountManager-container'>
						{accountManagers.map((ac, index: number) => {
							return (
								<p className='small-pill' key={"accountManager" + index}>
									{ac}
									{accountManagers.length > 1 && index != 0 && (
										<button
											type='button'
											onClick={() => removeAccountManager(index)}
										>
											X
										</button>
									)}
								</p>
							);
						})}

						{removedManagers?.map((ac, index: number) => {
							return (
								<p className='small-pill-red' key={"accountManager" + index}>
									{ac}

									<button
										type='button'
										onClick={() => addRemovedManager(ac, index)}
									>
										+
									</button>
								</p>
							);
						})}
					</div>
					<div className='singleAccountManager-row'>
						<input
							id='merchant_accountManagers'
							type='text'
							maxLength={100}
							name='merchantAccountManagers'
							value={singleManager}
							placeholder='Add 1 Email Address at a time'
							onChange={(event) => setSingleManager(event.target.value)}
						/>
						<button
							type='button'
							className='add-single-account-manager'
							onClick={() => singleManager && addAccountManager(singleManager)}
						>
							add
						</button>
					</div>
				</fieldset>

				<fieldset className='merchant' id='merchant-payment-info'>
					<p className='b'>Credit Card Information</p>

					<label htmlFor='ccName'>Name on card:</label>
					<input
						id='cc_name'
						type='text'
						maxLength={40}
						name='ccName'
						placeholder='Name on the Credit Card (40 chars max)'
						onChange={(event) => setCcName(event.target.value)}
					/>
					<label htmlFor='ccNumber'>Card Number:</label>
					<input
						id='cc_number'
						type='text'
						maxLength={19}
						name='ccNumber'
						placeholder='0123456789101112'
						onChange={(event) => setCcNumber(event.target.value)}
					/>
					<label htmlFor='ccExpiration'>Card Expiration:</label>
					<input
						id='cc_expiration'
						type='text'
						maxLength={5}
						name='ccExpiration'
						placeholder='Credit Card Expiration (MM/YY)'
						onChange={(event) => setCcExpiration(event.target.value)}
					/>
					<label htmlFor='ccCvc'>Credit Card CVC:</label>
					<input
						id='cc_cvc'
						type='text'
						maxLength={4}
						name='ccCvc'
						placeholder='Credit Card CVC'
						onChange={(event) => setCcCvc(event.target.value)}
					/>
				</fieldset>

				<fieldset id='new-merchant-send'>
					<input
						id='submitmerchant'
						type='button'
						className='button'
						value='Create Account'
						onClick={() => submitMerchant()}
					/>
				</fieldset>
			</form>
		</div>
	);
}

NewMerchant.propTypes = {
	pageSwap: PropTypes.func,
	uid: PropTypes.string,
	email: PropTypes.string,
	emails: PropTypes.array,
	merchantCoords: PropTypes.object,
	setMerchantInfo: PropTypes.func,
};

export default NewMerchant;
