import React from "react";
import PropTypes from "prop-types";
import "./Header.css";
import { PageTypes } from "./PageTypes";
import * as Logger from "./Utility/Logger";
import { Merchant } from "./models/Merchant.type";

const Header = (props: any) => {
	const userLogoClick = () => {
		Logger.log("user logo clicked");
		let logo = document.getElementById("user-logo-menu");
		if (logo) {
			Logger.log("logo found");
			if (logo.className.includes("hidden")) {
				Logger.log("hidden found");
				logo.className = logo.className.replace(" hidden", "");
			} else {
				Logger.log("hidden not found");
				logo.className += " hidden";
			}
		}
	};
	return (
		<div className='header'>
			<div className='header-display-group'>
				<h1>yay<em>!</em></h1>
			</div>
			{props.user ? (
				<>
					<button className='user-logo' onClick={() => userLogoClick()}>
						{props.user?.additionalUserInfo?.profile?.picture ? (
							<img
								src={props.user.additionalUserInfo.profile.picture}
								className='user-profile-picture'
							></img>
						) : (
							props.user.user.displayName
						)}
					</button>
					<div id='user-logo-menu' className='dropdown-content hidden'>
						<button
							onClick={() => {
								userLogoClick();
								props.pageSwap(PageTypes.VIEW_PROFILE);
							}}
						>
							View Profile
						</button>
						{/* TODO:CONSUMER */}
						{/* <button
							onClick={() => {
								userLogoClick();
								props.pageSwap(PageTypes.NEW_CONSUMER);
							}}
						>
							Create Consumer Account
						</button> */}
						{props.merchantInfo ? ( //Merchant Portion of the menu
							<>
								<button
									onClick={() => {
										userLogoClick();
										props.pageSwap(PageTypes.MERCHANT_CREATE_PERK);
									}}
								>
									Create A New Perk
								</button>
								<button
									onClick={() => {
										userLogoClick();
										props.pageSwap(PageTypes.VIEW_PERKS);
									}}
								>
									View My Perks
								</button>
								<button
									onClick={() => {
										userLogoClick();
										props.pageSwap(PageTypes.MERCHANT_HOME);
									}}
								>
									View My Merchants
								</button>
								<button
									onClick={() => {
										userLogoClick();
										props.pageSwap(PageTypes.NEW_MERCHANT);
									}}
								>
									Create A New Merchant
								</button>
							</>
						) : (
							//No Merchant Account Found, show the option to make one
							<button
								onClick={() => {
									userLogoClick();
									props.pageSwap(PageTypes.NEW_MERCHANT);
								}}
							>
								Create A Merchant Account
							</button>
						)}

						<button
							onClick={() => {
								let response = window.confirm("Are you sure?");
								if (response) {
									props.logout();
									props.pageSwap(PageTypes.LANDING);
								}
							}}
						>
							Sign Out
						</button>
					</div>
				</>
			) : (
				<button className='login button accent' onClick={() => props.loginClick()}>
					Login
				</button>
			)}
		</div>
	);
};

Header.propTypes = {
	loginClick: PropTypes.func,
	user: PropTypes.object,
	logout: PropTypes.func,
	pageSwap: PropTypes.func,
	merchantInfo: PropTypes.array || undefined,
};

export default Header;
