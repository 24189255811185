import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  addNewPerk,
  addNewUserProfile,
  fireStoreDb,
} from "../../auth/firebase";
import { PageTypes } from "../../PageTypes";
import * as Logger from "../../Utility/Logger";
import { UserProfile } from "../../models/UserProfile.type";

let markerPosition = {
  lat: 41.90476224706472,
  lng: 12.49822074385094,
};
let map: google.maps.Map, marker: any;

function CreateUserProfile(props: any) {
  let getLocationResponded = false;
  const mapZoom = 12;
  const [name, setName] = useState(props.user.user.displayName || "");
  const [emails, setEmails] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: 41.90476224706472,
    lng: 12.49822074385094,
  });
  const email = props.user?.user?.email;
  const gmapId = "5887eb4119999348";

  useEffect(() => {
    navigator.geolocation?.getCurrentPosition(
      (msg) => {
        Logger.log("success", msg);
        setMapCenter({
          lat: msg.coords.latitude,
          lng: msg.coords.longitude,
        });
        markerPosition = {
          lat: msg.coords.latitude,
          lng: msg.coords.longitude,
        };
        getLocationResponded = true;
      },
      (msg) => {
        Logger.error("error", msg);
        setMapCenter({
          lat: 41.90476224706472,
          lng: 12.49822074385094,
        });
        markerPosition = {
          lat: 41.90476224706472,
          lng: 12.49822074385094,
        };
        getLocationResponded = true;
      },
      { enableHighAccuracy: true }
    );
  }, []);
  useEffect(() => {
    initMap();
  }, [getLocationResponded]);

  async function initMap(): Promise<void> {
    // Request needed libraries.
    //@ts-ignore
    const { Map } = (await google.maps.importLibrary(
      "maps"
    )) as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      "marker"
    )) as google.maps.MarkerLibrary;
    map = new Map(document.getElementById("map") as HTMLElement, {
      zoom: mapZoom,
      center: mapCenter,
      mapId: gmapId,
      mapTypeId: "roadmap",
      disableDefaultUI: true,
      zoomControl: true,
      gestureHandling: "auto",
      disableDoubleClickZoom: true,
    });
    if (mapCenter) {
      marker = new AdvancedMarkerElement({
        gmpDraggable: true,
        map: map,
        position: mapCenter,
        title: "Perk Location",
      });
    }
    google.maps.event.addListener(marker, "dragend", function (e: any) {
      markerPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      // Logger.log("marker drag", markerPosition);
    });
    // google.maps.event.addListener(map, "drag", (e: any) => console.log("e", e));
    google.maps.event.addListener(map, "dblclick", function (e: any) {
      markerPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      Logger.log("Double Click", markerPosition);
      if (marker) {
        marker.setMap(null);
      }
      marker = new AdvancedMarkerElement({
        gmpDraggable: true,
        map: map,
        position: markerPosition,
        title: "Perk Location",
      });
    });
  }
  const submitUserProfile = () => {
    let joinDate = new Date(window.Date.now()).toDateString();

    let profile: UserProfile = {
      uid: props.uid,
      joinDate: joinDate,
      name: name,
      lastLoginDate: joinDate,
      authProvider:
        props.user?.additionalUserInfo?.providerId || "authProvider",
      email: email,
      homeBases: [markerPosition],
    };
    // Logger.log("Send this to our DB", props.user, perk);
    if (window.confirm("Are you sure?")) {
      addNewUserProfile(profile, PageTypes.NEW_MERCHANT, props.pageSwap);
    }
  };
  return (
    <div className="create-user-profile">
      <h1 className="page-title">Create a User Profile</h1>
      <p className="cen">This is just a little info about you as a person</p>

      <form id="submit-create-userprofile-form">
        <fieldset id="userprofile-content">
          <p className="b">User Profile</p>
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            required
            autoFocus
            maxLength={50}
            name="name"
            placeholder="Maximus Decimus Meridius"
            defaultValue={name}
            onChange={(event) => setName(event.target.value)}
          />
        </fieldset>

        <div id="map"></div>
        <fieldset id="userprofile-send">
          <input
            id="submit-userprofile"
            type="button"
            className="button"
            value="Create Your UserProfile"
            onClick={() => submitUserProfile()}
          />
        </fieldset>
      </form>
    </div>
  );
}

CreateUserProfile.propTypes = {
  user: PropTypes.object,
  uid: PropTypes.string,
  pageSwap: PropTypes.func,
};

export default CreateUserProfile;
