import React from "react";
import PropTypes from "prop-types";
import { PageTypes } from "../PageTypes";

function Error(props: any) {
	return <div>ERROR Page</div>;
}

Error.propTypes = {};

export default Error;
