import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Logger from "../../Utility/Logger";

function ViewUserProfile(props: any) {
	useEffect(() => {
		Logger.log("- uid:", props.uid);
		props.callToUpdateUserProfile("ViewUserProfile");
	}, [props.uid]);

	return (
		<div>
			<h1 className="page-title">ViewUserProfile</h1>
			{props.userProfile && (
				<>
					<h2>UserProfile</h2>
					{Object.keys(props.userProfile).map((key: string) => {
						let type = typeof props.userProfile[key];
						if (type === "object") {
							let innerObj = props.userProfile[key];
							let innerKeys = Object.keys(innerObj);

							return (
								<div key={key}>
									<h3>{key}</h3>
									{innerKeys.map((innerKey: string) => {
										let innerType = typeof innerObj[innerKey];
										return (
											<p key={innerKey}>{"--" + innerKey + ": " + innerType}</p>
										);
									})}
								</div>
							);
						}
						return <p>{key + ": " + type}</p>;
					})}
				</>
			)}
			<h2>User</h2>
			{props.user &&
				Object.keys(props.user).map((key: string) => {
					let type = typeof props.user[key];
					if (type === "object") {
						let innerObj = props.user[key];
						let innerKeys = Object.keys(innerObj);

						return (
							<div>
								<h3>{key}</h3>
								{innerKeys.map((innerKey: string) => {
									let innerType = typeof innerObj[innerKey];
									return <p>{"--" + innerKey + ": " + innerType}</p>;
								})}
							</div>
						);
					}
					return <p>{key + ": " + type}</p>;
				})}
		</div>
	);
}

ViewUserProfile.propTypes = {
	user: PropTypes.object,
	userProfile: PropTypes.object,
	uid: PropTypes.string,
	callToUpdateUserProfile: PropTypes.func,
};

export default ViewUserProfile;
