import { PageTypes } from "../PageTypes";
import firebase from "firebase/compat/app";
import * as Logger from "../Utility/Logger";
const LSO_KEY = "yayperks-user";
const INCREMENT = 30 * 60000;//30 min
export const login = (user) => {

    Logger.log("login called");
    let newUser = { ...user, expiration: window.Date.now() + INCREMENT };
    setLocal(newUser);
};
export const startupLogin = () => {
    let lso = localStorage.getItem(LSO_KEY);
    if (lso) {
        Logger.log("startupLogin", "lso found");
        let user = JSON.parse(lso);
        if (user.expiration > window.Date.now()) {
            Logger.log("startupLogin", "user accepted");
            return user;
        } else {
            Logger.log("startupLogin", "user expired");
            logOut();
        }
    }
    Logger.log("startupLogin", "no lso found");
    return false;
};
const setLocal = (newUser) => {
    Logger.log("setting Local");
    localStorage.setItem(LSO_KEY, JSON.stringify(newUser));
};
export const logOut = () => {
    Logger.log("logout called");
    localStorage.removeItem(LSO_KEY);
};
export const interact = (logout) => {
    Logger.log("user interaction");
    let existingItem = JSON.parse(localStorage.getItem(LSO_KEY));
    if (existingItem?.expiration < window.Date.now()) {
        logout();
        return false;
    }
    let newItem = JSON.stringify({ ...existingItem, expiration: window.Date.now() + INCREMENT });
    if (newItem.user) localStorage.setItem(LSO_KEY, newItem);
};
export const getUiConfig = (props) => {
    return {
        callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                Logger.log("authResult", authResult);
                // instance.userUID = authResult.user.uid;
                props.setAppUser(authResult);

                // instance.props.setAppUser({ uid: authResult.user.uid, displayName: authResult.user.displayName });
                // user = authResult.user;
                props.pageSwap(PageTypes.LANDING);

                return false;
            },
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: "popup",
        // signInSuccessUrl: `PLAYER/`,
        signInOptions: [
            {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                // Allow the user the ability to complete sign-in cross device,
                // including the mobile apps specified in the ActionCodeSettings
                // object below.
                forceSameDevice: false,

            },
            {
                provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                // Allow the user the ability to complete sign-in cross device,
                // including the mobile apps specified in the ActionCodeSettings
                // object below.
                forceSameDevice: false,

            },
            // {
            //     provider: firebase.auth.GithubAuthProvider.PROVIDER_ID,
            //     // Allow the user the ability to complete sign-in cross device,
            //     // including the mobile apps specified in the ActionCodeSettings
            //     // object below.
            //     forceSameDevice: false,

            // },
            {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                // Allow the user the ability to complete sign-in cross device,
                // including the mobile apps specified in the ActionCodeSettings
                // object below.
                forceSameDevice: false,

            },
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                // Allow the user the ability to complete sign-in cross device,
                // including the mobile apps specified in the ActionCodeSettings
                // object below.
                forceSameDevice: false,
                // Used to define the optional firebase.auth.ActionCodeSettings if
                // additional state needs to be passed along request and whether to open
                // the link in a mobile app if it is installed.
                emailLinkSignIn: function () {
                    return {
                        // Additional state showPromo=1234 can be retrieved from URL on
                        // sign-in completion in signInSuccess callback by checking
                        // window.location.href.
                        url: 'https://www.example.com/completeSignIn?showPromo=1234',
                        // Custom FDL domain.
                        dynamicLinkDomain: 'example.page.link',
                        // Always true for email link sign-in.
                        handleCodeInApp: true,
                        // Whether to handle link in iOS app if installed.
                        iOS: {
                            bundleId: 'com.example.ios'
                        },
                        // Whether to handle link in Android app if opened in an Android
                        // device.
                        android: {
                            packageName: 'com.example.android',
                            installApp: true,
                            minimumVersion: '12'
                        }
                    };
                }
            },

        ],
        // TODO:Terms of service url.
        tosUrl: "<your-tos-url>",
        // TODO:Privacy policy url.
        privacyPolicyUrl: "<your-privacy-policy-url>",
    };
};