import React from "react";
import CreatePerk from "./Pages/Perk/CreatePerk";
import PropTypes from "prop-types";
import About from "./Pages/About";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import Error from "./Pages/Error";
import Landing from "./Pages/Landing";
import LoginPage from "./Pages/Login";
import ViewPerks from "./Pages/Perk/ViewPerks";
import MerchantHome from "./Pages/Merchant/MerchantHome";
import { PageTypes } from "./PageTypes";
import ConsumerHome from "./Pages/Consumer/ConsumerHome";
import NewMerchant from "./Pages/Merchant/NewMerchant";
import NewConsumer from "./Pages/Consumer/NewConsumer";
import ViewUserProfile from "./Pages/User/ViewUserProfile";
import CreateUserProfile from "./Pages/User/CreateUserProfile";

const PageContainer = (props: any) => {
	switch (props.page.pageName) {
		case PageTypes.LANDING:
			return (
				<Landing
					pageSwap={props.pageSwap}
					setMerchantInfo={props.setMerchantInfo}
					setConsumerInfo={props.setConsumerInfo}
					merchantInfo={props.merchantInfo}
					consumerInfo={props.consumerInfo}
					uid={props.user?.user?.uid}
					userProfile={props.userProfile}
					callToUpdateMerchantInfo={props.callToUpdateMerchantInfo}
					callToUpdateUserProfile={props.callToUpdateUserProfile}
					callToUpdateConsumerInfo={props.callToUpdateConsumerInfo}
					{...props.page?.pageSwapProps}
				/>
			);
		case PageTypes.VIEW_PROFILE:
			return (
				<ViewUserProfile
					user={props.user}
					uid={props.user?.user?.uid}
					userProfile={props.userProfile}
					callToUpdateUserProfile={props.callToUpdateUserProfile}
					{...props.page?.pageSwapProps}
				/>
			);
		case PageTypes.LOGIN:
			return (
				<LoginPage
					pageSwap={props.pageSwap}
					setAppUser={props.setAppUser}
					{...props.page?.pageSwapProps}
				/>
			);
		case PageTypes.MERCHANT_HOME:
			return (
				<MerchantHome
					callToUpdateMerchantInfo={props.callToUpdateMerchantInfo}
					pageSwap={props.pageSwap}
					merchantInfo={props.merchantInfo}
					uid={props.user?.user?.uid}
					{...props.page?.pageSwapProps}
				/>
			);
		//TODO:CONSUMER
		// case PageTypes.CONSUMER_HOME:
		// 	return (
		// 		<ConsumerHome
		// 			pageSwap={props.pageSwap}
		// 			user={props.user}
		// 			consumerInfo={props.consumerInfo}
		// 		/>
		// 	);
		case PageTypes.MERCHANT_CREATE_PERK:
			return (
				<CreatePerk
					uid={props.user?.user?.uid}
					pageSwap={props.pageSwap}
					merchantInfo={props.merchantInfo}
					merchantCoords={props.merchantCoords}
					{...props.page?.pageSwapProps}
				/>
			);
		case PageTypes.CREATE_PROFILE:
			return (
				<CreateUserProfile
					user={props.user}
					uid={props.user?.user?.uid}
					pageSwap={props.pageSwap}
					{...props.page?.pageSwapProps}
				/>
			);
		case PageTypes.NEW_MERCHANT:
			return (
				<NewMerchant
					pageSwap={props.pageSwap}
					uid={props.user?.user?.uid}
					email={props.user?.user?.email}
					merchantCoords={props.merchantCoords}
					setMerchantInfo={props.setMerchantInfo}
					{...props.page?.pageSwapProps}
				/>
			);
		// case PageTypes.NEW_CONSUMER:
		// 	return (
		// 		<NewConsumer
		// 			pageSwap={props.pageSwap}
		// 			consumerInfo={props.consumerInfo}
		// 		/>
		// 	);
		case PageTypes.VIEW_PERKS:
			return (
				<ViewPerks
					uid={props.user?.user?.uid}
					merchantInfo={props.merchantInfo}
					pageSwap={props.pageSwap}
					{...props.page?.pageSwapProps}
				/>
			);
		case PageTypes.ABOUT:
			return <About pageSwap={props.pageSwap} user={props.user} />;
		case PageTypes.TERMS:
			return <Terms pageSwap={props.pageSwap} user={props.user} />;
		case PageTypes.PRIVACY:
			return <Privacy pageSwap={props.pageSwap} user={props.user} />;
		default:
			return <Error />;
	}
};
PageContainer.propTypes = {
	page: PropTypes.object,
	pageSwap: PropTypes.func,
	setAppUser: PropTypes.func,
	setMerchantInfo: PropTypes.func,
	merchantInfo: PropTypes.array,
	setConsumerInfo: PropTypes.func,
	consumerInfo: PropTypes.object,
	user: PropTypes.object,
	userProfile: PropTypes.object,
	callToUpdateMerchantInfo: PropTypes.func,
	callToUpdateUserProfile: PropTypes.func,
	// callToUpdateConsumerInfo: PropTypes.func,
};
export default PageContainer;
