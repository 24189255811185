import { useEffect } from "react";
import { ui } from "../auth/firebase";
import "firebase/compat/auth";
import "./Login.css";
import { getUiConfig } from "../auth/AuthUtil";
import * as Logger from "../Utility/Logger";
import { PageTypes } from "../PageTypes";

const LoginPage = (props) => {
	useEffect(() => {
		Logger.network("Login UI Starting");
		ui.start("#firebaseui-auth-container", getUiConfig(props));
	}, [1]);

	return (
		<div className='LoginPage-screen' >
			<div id='firebaseui-auth-container' />
			<div className="back-button-container">
				<button className="back-to-home-button button" onClick={()=>props.pageSwap(PageTypes.LANDING)}>Back to Home Page</button>
			</div>
		</div>
	);
};
export default LoginPage;