import React from "react";
import PropTypes from "prop-types";

function Terms(props: any) {
	return (
    <><h2 className="cen">Terms of Service</h2>
    <p className="cen effective">Effective: January 1, 2024</p>

    <h3>Welcome to Yayperks!</h3>
    <p>Thank you for your interest in our service. You accept these terms of service when you sign up to use our service.</p>

    <h3>User Responsibilities</h3>
    <p>By using our mobile and/or web apps and signing up, you represent, acknowledge and agree that you are at least 18 years of age, or if you are under 18 years of age but are at least 13 years old, that you are using Yayperks with the consent of your parent or legal guardian and that you have received your parent&rsquo;s or legal guardian&rsquo;s permission to enter into this Agreement. If you are a parent or legal guardian who is registering for a child, you hereby agree to bind your child to this Agreement and to fully indemnify, release and hold harmless Yayperks if your child breaches or disaffirms any term or condition of this agreement.</p>

    <h3>Frequency of notifications</h3>
    <p>You will receive a variable amount of notifications. The amount depends on many factors including but not limited to, location, time, and merchant participation. We reserve the right to limit, cap, or cease notifications without warning. We reserve the right to implement tools to manage notification frequency.</p>

    <h3>Objectionable notification content</h3>
    <p>We perform due dilligence to only accept established merchants who are in good standing within their respective communities. Notifications that you receive via the service are created by our participating merchants who have signed up to use the service, and have agreed to the <a href="/merchants/terms/">merchant terms of service</a>. Should you receive a notification that you find to be offensive, inaccurate, abusive, or otherwise objectionable, please report it immediately to support@yayperks.com.</p>

    <p>We provide reasonable safeguards to help filter and prevent the creation and transmission of objectionable words and/or phrases. Yayperks is in no way responsible for any damages or hardships you may incur by receiving notifications from the merchants or using this service.</p>

    <h3>Data Charges</h3>
    <p>Yayperks is not responsible for any costs that you incur by using this service.</p>

    <h3>Membership Status</h3>
    <p>Yayperks reserves the right to refuse any and all current or future use of the app, website, or the service, and not to do business with any member or anyone else, as Yayperks deems appropriate in its sole discretion for any reason, without any warning or restriction.</p>

    <h3>Stop Instructions (Opting out)</h3>
    <p>If you do not accept our terms of service, or no longer wish to receive notifications, simply uninstall our application.</p></>
	);
}

Terms.propTypes = { pageSwap: PropTypes.func, user: PropTypes.object };

export default Terms;
